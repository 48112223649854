@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';

.root {
    align-items: center;
    display: grid;
    font-size: 0.875rem;
    gap: 0.75rem;
    grid-template-columns: auto 1fr;
    padding: 1rem 0;
    position: relative;

    + .root {
        border-top: 1px solid colors.$color--gallery;
    }
}

.quantityLineWrapper {
    position: relative;
}

.removeButton {
    @include mixins.trigger-icon-no-background();
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;

    &:focus,
    &:hover {
        color: colors.$text-color--red;
        outline: none;
    }
}

.itemContent {
    display: grid;
    gap: 0.25rem;
}

.itemName {
    @include mixins.heading-4();

    font-size: 0.875rem;
    text-transform: none;
}

.itemLink {
    @include mixins.link-underline(colors.$text-color--primary, colors.$text-color--primary);
}

.itemPrice {
    flex-direction: row;
    flex-wrap: wrap;
}

.itemPriceOld {
    color: colors.$text-color--lighter;
    text-decoration: line-through;
}

.stockStatus {
    color: colors.$text-color--red;
    font-weight: fonts.$font-weight--medium;
    text-transform: uppercase;
}

.itemImage {
    img {
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}
